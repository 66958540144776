import {
  AnalyticsQuery,
  exportAnalyticsQuery,
} from "../types/indicatorsAnalytics";
import {
  CampaignAnalyticsQuery,
  exportCampaignAnalyticsQuery,
} from "../types/campaignAnalytics";
import {
  ChannelSubscriberQuery,
  exportChannelSubscriberQuery,
  listChannelQuery,
} from "../types/channel";
import {
  EventsAnalyticsQuery,
  exportEventsAnalyticsQuery,
} from "../types/events";
import { JourneySearch } from "../types/journey";
import { MessageQuery } from "../types/campaign";
import { ResetPasswordQuery } from "../types/userInfo";
import { SegmentsSearch } from "../types/segments";
import { exportABtestReportQuery } from "../types/windowsPools";
import { exportContentQuery } from "../types/contentInsights";

export const makeQueryString: (queryObject: object) => string = (
  queryObject
) => {
  const cleanedObject = { ...queryObject };
  Object.keys(cleanedObject).forEach(
    // @ts-ignore
    (key) => cleanedObject[key] === undefined && delete cleanedObject[key]
  );
  const queryString = new URLSearchParams(cleanedObject).toString();
  return queryString ? `?${queryString}` : "";
};

const endPoints = {
  login: () => "users/authentication/",
  register: () => "users/registration/",
  refreshToken: () => "users/authorization/",
  resendEmail: () => "users/resend-email/",
  listChannel: (query?: listChannelQuery) =>
    "channels/" +
    (query
      ? makeQueryString({
          page: query?.page,
          name: query?.name,
          status: query?.status,
          is_used_campaigns: query?.is_used_campaigns,
          exclude: query?.exclude,
        })
      : ""),
  channelDashboard: () => "channels-dashboard/",
  createChannel: () => "channels/",
  channelById: (id: string | number | undefined) => `channels/${id}/`,
  duplicationChannelById: (id: string | number | undefined) =>
    `channels/${id}/duplication/`,
  bulkDuplicationChannel: () => `channels/bulk/duplication/`,
  bulkArchiveChannel: () => `/channels/bulk/archive/`,
  channelPermission: () => "channel-permissions/",
  uploadImage: () => "/images/",
  verifyCodeSnippet: (id: string | undefined) => `channels/${id}/verification/`,
  getSubscribers: (query: ChannelSubscriberQuery) =>
    `statistics/subscribers/` + makeQueryString(query),
  pageConfig: () => "page-configs/",
  pageConfigById: (id: string | number) => `page-configs/${id}/`,
  listCampaign: () => `campaigns/`,
  CampaignDashboard: () => `/campaigns/dashboard/`,
  CampaignAnalytics: () => `/campaigns/analytics/`,
  createCampaign: () => `campaigns/`,
  campaignById: (id: string | number | undefined) => `campaigns/${id}/`,
  bulkDuplicationCampaign: () => `campaigns/bulk/duplication/`,
  bulkArchiveCampaign: () => `/campaigns/bulk/archive/`,
  bulkStopCampaign: () => `/campaigns/bulk/stop/`,
  messagePools: () => "message-pools/",
  randomReviewTemplate: (id: string | number) =>
    `message-pools/${id}/pick_random/`,
  messageTemplateList: (query?: MessageQuery) =>
    "message-templates/" +
    (query
      ? makeQueryString({
          campaign_id: query?.campaign_id,
        })
      : ""),
  messageTemplate: (id?: number) => "message-templates/" + (id ? `${id}/` : ""),
  trackingFunction: () => "templates-functions/",
  listMessage: () => `message-templates/`,
  // push pool
  messagePoolsById: (id: string | number) => `message-pools/${id}/`,
  PoolsDashboard: () => `pools-dashboard/`,
  createPushMessageTemplate: () => `message-pools/`,
  bulkDuplicationPushMessageTemplate: () => `message-pools/bulk/duplication/`,
  bulkArchivePushMessageTemplate: () => `/message-pools/bulk/archive/`,

  listImage: (image_ids?: Array<number>) =>
    "/images/" +
    (image_ids && image_ids.length > 0
      ? makeQueryString({
          image_ids: image_ids.join(","),
        })
      : ""),
  exportsSubscribers: (query: exportChannelSubscriberQuery) =>
    `exports/subscribers/` + makeQueryString(query),

  indicatorsAnalytics: (query?: AnalyticsQuery) =>
    "/statistics/analytics/" +
    (query
      ? makeQueryString({
          channels: query?.channels,
          start: query?.start,
          end: query?.end,
          step: query?.step ?? "day",
          merge_channels: query?.merge_channels ?? 1,
          order_by: query?.order_by,
        })
      : ""),
  subscribersSourcesDashboard: () => `sources-dashboard/`,
  exportsIndicatorsAnalytics: (query: exportAnalyticsQuery) =>
    `exports/analytics/` + makeQueryString(query),
  // Subscription window pools
  WindowsPoolsDashboard: () => `windows-pools/dashboard/`,
  windowsPoolsById: (id: string | number) => `windows-pools/${id}/`,
  WindowsPoolsAutocomplete: () => `windows-pools/autocomplete/`,
  WindowsPoolsPickRandom: () => `windows-pools/pick_random/`,
  createwindowsPools: () => `windows-pools/`,
  bulkDuplicationWindowsPools: () => `windows-pools/bulk/duplication/`,
  bulkArchiveWindowsPools: () => `/windows-pools/bulk/archive/`,

  listSubscribersSourceNames: (channel_id: string | number) =>
    `subscribers-sources/` +
    makeQueryString({
      channel_id: channel_id,
    }),
  WindowsPoolsAnalyticsDashboard: (report_id: string) =>
    `windows-pools/${report_id}/analytics/`,
  WindowsPoolsWinner: (
    window_pool_id: string | number,
    window_id: string | number
  ) => `windows-pools/${window_pool_id}/windows/${window_id}/winner/`,
  SubscriptionWindowsDashboard: () => `windows/dashboard/`,
  WindowsPoolsBanned: (
    window_pool_id: string | number,
    window_id: string | number
  ) => `windows-pools/${window_pool_id}/windows/${window_id}/`,
  bulkBannedWindowsPools: (window_pool_id: string | number) =>
    `windows-pools/${window_pool_id}/windows/bulk/`,

  createSubscriptionWindow: () => "windows/",
  SubscriptionWindowById: (id: string | number | undefined) => `windows/${id}/`,
  SubscriptionWindowAutocomplete: () => `windows/autocomplete/`,
  campaignsAnalytics: (query?: CampaignAnalyticsQuery) =>
    "/statistics/campaigns/" +
    (query
      ? makeQueryString({
          campaign_ids: query?.campaign,
          start: query?.start,
          end: query?.end,
          step: query?.step ?? "day",
          order_by: query?.order_by,
        })
      : ""),
  userInfo: () => "/users/me/",
  userProfile: () => "/users/profile/",
  userPassword: () => "/users/password/",
  userPasswordVerify: () => "/users/password/verify/",
  userQuotas: () => "/users/quotas/",
  userSubscribersLimitations: () => "/subscribers/limitations/",

  listNotifications: (page: number) =>
    `/notifications/` +
    makeQueryString({
      page: page,
    }),
  notificationsCount: () => "/notifications/count/",
  notificationsStatus: () => "/notifications/status/",
  userResetPassword: () => "/users/password/reset/",
  userSetNewPassword: (query: ResetPasswordQuery) =>
    `/users/password/new/${query.user_uid}/${query.token}/`,
  contentInsightsDashboard: (id: string) =>
    `/campaigns/${id}/content-analytics/`,
  messageExamples: (id: string | number) => `message-examples/${id}/`,
  exportsCampaignAnalytics: (query: exportCampaignAnalyticsQuery) =>
    `exports/campaigns/` + makeQueryString(query),
  exportsContentInsights: (query: exportContentQuery) =>
    `exports/content_insights/` + makeQueryString(query),
  // segments
  listSegments: (query?: SegmentsSearch) =>
    `segments/` +
    (query
      ? makeQueryString({
          name: query?.searchString,
          channel_id: query?.channel_id,
        })
      : ""),
  SegmentsDashboard: () => `segments/dashboard/`,
  SegmentsAutocomplete: (query?: SegmentsSearch) =>
    `segments/autocomplete/` +
    (query
      ? makeQueryString({
          name: query?.searchString,
        })
      : ""),
  windowsPreviewReadById: (id: string | number) => `windows/preview/${id}/`,
  // Get code snippet data
  channelCodeSnippet: () => `code-snippet/`,
  // get exportsABTestReport
  exportsABTestReport: (query: exportABtestReportQuery) =>
    `exports/ab_report/` + makeQueryString(query),
  // get segment by id
  segmentById: (id: string | number | undefined) => `segments/${id}/`,
  createSegment: () => "segments/",
  subscribersParamsById: (id?: string | number) =>
    "/subscribers-params/" +
    makeQueryString({
      channel_id: id,
    }),
  windowPoolscontentInsightsDashboard: (id: string) =>
    `channels/${id}/content-analytics/`,
  paymentsCheckout: () => "payments/checkout/",
  paymentsCheckoutValidate: (session_id: string | number) =>
    "payments/checkout/" +
    makeQueryString({
      session_id: session_id,
    }),
  availablePricesList: () => "payments/price/",
  userSubscriptionDetails: () => "payments/subscription/",
  subscribersTotal: () => "subscribers/total/",
  userInvoices: () => "payments/invoices/",
  downloadWebSdk: () => "channels/download-web-sdk/",
  channelPWA: () => `channels/pwa/`,
  userFirebaseCredentialsList: () => `user-firebase-credentials/`,
  firebaseAccounts: () => `user-firebase-credentials/`,
  FirebaseAccountsDashboard: () => `firebase-dashboard/`,
  firebaseAccountsById: (id: string | number | undefined) =>
    `user-firebase-credentials/${id}/`,
  // archive
  archiveChannelById: (id: string | number | undefined) =>
    `channels/${id}/archive/`,
  unArchiveChannelById: (id: string | number | undefined) =>
    `channels/${id}/unarchive/`,
  archiveCampaignsById: (id: string | number | undefined) =>
    `campaigns/${id}/archive/`,
  unArchiveCampaignsById: (id: string | number | undefined) =>
    `campaigns/${id}/unarchive/`,
  poolOptimisationByCampaignId: (id: string | number | undefined) =>
    `campaigns/${id}/pool_optimisation/`,
  archiveSegmentById: (id: string | number | undefined) =>
    `segments/${id}/archive/`,
  unArchiveSegmentById: (id: string | number | undefined) =>
    `segments/${id}/unarchive/`,
  archivePushNotificationById: (id: string | number | undefined) =>
    `message-pools/${id}/archive/`,
  unArchivePushNotificationById: (id: string | number | undefined) =>
    `message-pools/${id}/unarchive/`,
  archiveWindowsPoolsById: (id: string | number | undefined) =>
    `windows-pools/${id}/archive/`,
  unArchiveWindowsPoolsById: (id: string | number | undefined) =>
    `windows-pools/${id}/unarchive/`,
  eventsById: (id: string | number | undefined) => `events/${id}/`,
  createEvents: () => `events/`,
  listEvents: () => "events/",
  eventsDashboard: () => "events/dashboard/",
  eventsAnalytics: (query?: EventsAnalyticsQuery) =>
    "/statistics/event_daily_data/" +
    (query
      ? makeQueryString({
          event_id: query?.event_id,
          start: query?.start,
          end: query?.end,
        })
      : ""),
  exportsEventsAnalytics: (query: exportEventsAnalyticsQuery) =>
    `exports/events/` + makeQueryString(query),
  JourneyDashboard: () => `journeys/dashboard/`,
  JourneyAutocomplete: (query?: JourneySearch) =>
    `journeys/autocomplete/` +
    (query
      ? makeQueryString({
          name: query?.searchString,
        })
      : ""),
  createJourney: () => `journeys/`,
  journeyById: (id: string | number | undefined) => `journeys/${id}/`,
  archiveJourneyById: (id: string | number | undefined) =>
    `journeys/${id}/archive/`,
  duplicationJourneyById: (id: string | number | undefined) =>
    `journeys/${id}/duplication/`,
  journeyElementsById: (
    journey_id: string | number | undefined,
    id?: string | number | undefined
  ) => `journeys/${journey_id}/elements/${id ? `${id}/` : ""}`,
  postBackEcho: () => `postbacks/`,
  createPostBackEcho: () => "postbacks/",
  postBackById: (id: string | number | undefined) => `postbacks/${id}/`,
};
export default endPoints;
