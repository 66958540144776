import { API_MANAGER_URL } from "../types/env";
import {
  AnalyticsQuery,
  exportAnalyticsQuery,
} from "../types/indicatorsAnalytics";
import {
  AuthResponse,
  LoginRequest,
  Prices,
  RegisterRequest,
  RegisterResponse,
  UserPayment,
  UserSubscription,
} from "../types/auth";
import { AxiosResponse } from "axios";
import {
  Campaign,
  CampaignAnalytics,
  MessageQuery,
  MessageTemplate,
  PoolOptimisationConfig,
  Template,
  TemplateExample,
} from "../types/campaign";
import {
  CampaignAnalyticsQuery,
  exportCampaignAnalyticsQuery,
} from "../types/campaignAnalytics";
import {
  Channel,
  ChannelDetail,
  ChannelPermission,
  ChannelSubscriberQuery,
  ChannelSubscriberResponse,
  PostbackEcho,
  SnippetVerifyResponse,
  SubscriptionWindow,
  exportChannelSubscriberQuery,
  listChannelQuery,
} from "../types/channel";
import {
  ContentInsights,
  ContentInsightsFavroites,
  exportContentQuery,
} from "../types/contentInsights";
import {
  EventObject,
  EventsAnalyticsQuery,
  exportEventsAnalyticsQuery,
} from "../types/events";
import { FirebaseAccounts } from "../types/firebaseAccounts";
import { ImageObj, PushMessageTemplate } from "../types/pushNotification";
import { Journey, JourneyElements } from "../types/journey";
import {
  ResetPasswordQuery,
  SubscribersLimitations,
  UserInvoices,
  userQuotas,
  usersInfo,
  usersPassword,
} from "../types/userInfo";
import { Segments, SegmentsSearch } from "../types/segments";
import { ServerSideRequest, ServerSideResponse } from "../types/query";
import {
  SubscribersSource,
  SubscribersSourceList,
} from "../types/subscribersSource";
import { UserPageConfig } from "../types/dashboard";
import { WindowPoolsContentInsights } from "../types/windowPoolsContentInsights";
import {
  WindowsPools,
  WindowsPoolsReport,
  WindowsPoolsWinnerQuery,
  exportABtestReportQuery,
} from "../types/windowsPools";
import { WindowsPreview } from "../types/windowsPools";
import { convertCustomizeModel } from "./campaignService";
import { convertDateModel } from "./channelServices";
import { fetcher, makeOptions } from "./baseRequest";
import {
  notificationCount,
  notificationInfiniteScroll,
  notificationStatusObj,
} from "../types/notification";
import _ from "lodash";
import endPoints from "./endpoints";

export const loginApi = async (payload: LoginRequest) =>
  fetcher<AuthResponse>(
    endPoints.login(),
    makeOptions({
      method: "POST",
      payload,
    })
  );

export const registerApi = async (payload: RegisterRequest) =>
  fetcher<RegisterResponse>(
    endPoints.register(),
    makeOptions({
      method: "POST",
      payload,
    })
  );

export const refreshTokenApi = async () =>
  fetcher<AuthResponse>(
    endPoints.refreshToken(),
    makeOptions({
      withAuth: true,
    })
  );

export const refreshNewTokenApi = async (userToken: string) =>
  fetcher<AuthResponse>(
    endPoints.login(),
    makeOptions({
      method: "POST",
      payload: { on_behalf_token: userToken },
    })
  );

export const listChannel = async (query?: listChannelQuery) =>
  fetcher<Channel[]>(
    endPoints.listChannel(query),
    makeOptions({
      withAuth: true,
    })
  );

export const listPageConfig = async (token?: string) => {
  const option = makeOptions({ withAuth: true });
  return fetcher<UserPageConfig[]>(
    endPoints.pageConfig(),
    !!token && !option?.headers?.Authorization
      ? {
          ...option,
          headers: {
            ...option.headers,
            Authorization: `Bearer ${token}`,
          },
        }
      : option
  );
};

export const createPageConfig = async (payload: Omit<UserPageConfig, "id">) =>
  fetcher<UserPageConfig>(
    endPoints.pageConfig(),
    makeOptions({ withAuth: true, method: "POST", payload })
  );

export const updatePageConfig = async (payload: UserPageConfig) =>
  fetcher<UserPageConfig>(
    endPoints.pageConfigById(payload.id),
    makeOptions({ withAuth: true, method: "PUT", payload })
  );

export const fetchChannelsDashboard = async ({
  endRow,
  startRow,
  filterModel,
  sortModel,
}: ServerSideRequest) =>
  fetcher<ServerSideResponse<Channel>>(
    endPoints.channelDashboard(),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel: convertDateModel(filterModel),
        sortModel,
      },
      method: "POST",
    })
  );

export const createChannel = async (channel: Channel) =>
  fetcher<Channel>(
    endPoints.createChannel(),
    makeOptions({
      withAuth: true,
      payload: channel,
      method: "POST",
    })
  );

export const updateChannel = async (channel: Channel) =>
  fetcher<Channel>(
    endPoints.channelById(channel.channel_id),
    makeOptions({
      withAuth: true,
      payload: channel,
      method: channel?.custom_attributes ? "PUT" : "PATCH",
    })
  );

export const getChannelById = async (id: string | number) =>
  fetcher<ChannelDetail>(
    endPoints.channelById(id),
    makeOptions({ withAuth: true })
  );

export const duplicationChannel = async (channel: Channel) =>
  fetcher<Channel>(
    endPoints.duplicationChannelById(channel.channel_id),
    makeOptions({
      withAuth: true,
      payload: _.omit(channel, "channel_id", "id"),
      method: "POST",
    })
  );

export const bulkDuplicationChannel = async (ids: any[]) =>
  fetcher<Channel[]>(
    endPoints.bulkDuplicationChannel(),
    makeOptions({
      withAuth: true,
      payload: ids,
      method: "POST",
    })
  );

export const bulkArchiveChannel = async (ids: any[]) =>
  fetcher<Channel[]>(
    endPoints.bulkArchiveChannel(),
    makeOptions({
      withAuth: true,
      payload: ids,
      method: "POST",
    })
  );

export const addUserToChannel = async (permission: ChannelPermission) =>
  fetcher<ChannelPermission>(
    endPoints.channelPermission(),
    makeOptions({
      withAuth: true,
      payload: permission,
      method: "POST",
    })
  );

export const listChannelPermission = async () =>
  fetcher<ChannelPermission[]>(
    endPoints.channelPermission(),
    makeOptions({ withAuth: true })
  );

export const uploadImage = async (file: FormData) =>
  fetcher<ImageObj>(
    endPoints.uploadImage(),
    makeOptions({
      withAuth: true,
      method: "POST",
      payload: file,
      contentType: "multipart/form-data",
    })
  );

export const verifyCodeSnippet = async (id: string, path: string) =>
  fetcher<SnippetVerifyResponse>(
    endPoints.verifyCodeSnippet(id),
    makeOptions({ withAuth: true, method: "POST", payload: { path } })
  );

export const getChannelSubscribers = async (query: ChannelSubscriberQuery) =>
  fetcher<ChannelSubscriberResponse>(
    endPoints.getSubscribers(query),
    makeOptions({ withAuth: true })
  );

export const createCampaign = async (query: Campaign) => {
  return fetcher<Campaign>(
    endPoints.createCampaign(),
    makeOptions({ withAuth: true, method: "POST", payload: query })
  );
};

export const listCampaigns = async () => {
  return fetcher<Campaign[]>(
    endPoints.listCampaign(),
    makeOptions({ withAuth: true })
  );
};

export const fetchCampaignsDashboard = async ({
  endRow,
  startRow,
  filterModel,
  sortModel,
}: ServerSideRequest) =>
  fetcher<ServerSideResponse<Campaign>>(
    endPoints.CampaignDashboard(),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel,
        sortModel,
      },
      method: "POST",
    })
  );

export const fetchCampaignsAnalytics = async ({
  endRow,
  startRow,
  filterModel,
  sortModel,
}: ServerSideRequest) =>
  fetcher<ServerSideResponse<CampaignAnalytics>>(
    endPoints.CampaignAnalytics(),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        daily: true,
        filterModel: convertCustomizeModel(filterModel),
        sortModel,
      },
      method: "POST",
    })
  );

export const getCampaignById = async (id: string) => {
  return fetcher<Campaign>(
    endPoints.campaignById(id),
    makeOptions({ withAuth: true })
  );
};

export const updateCampaignById = async (id: number, campaign: Campaign) => {
  return fetcher<Campaign>(
    endPoints.campaignById(id),
    makeOptions({
      withAuth: true,
      method: "PATCH",
      payload: { ...campaign, id: undefined },
    })
  );
};

export const bulkDuplicationCampaign = async (ids: any[]) =>
  fetcher<Campaign[]>(
    endPoints.bulkDuplicationCampaign(),
    makeOptions({
      withAuth: true,
      payload: ids,
      method: "POST",
    })
  );

export const bulkArchiveCampaign = async (ids: any[]) =>
  fetcher<any>(
    endPoints.bulkArchiveCampaign(),
    makeOptions({
      withAuth: true,
      payload: ids,
      method: "POST",
    })
  );

export const bulkStopCampaign = async (ids: any[]) =>
  fetcher<any>(
    endPoints.bulkStopCampaign(),
    makeOptions({
      withAuth: true,
      payload: ids,
      method: "POST",
    })
  );

export const listTemplates = async () => {
  return fetcher<Template[]>(
    endPoints.messagePools(),
    makeOptions({ withAuth: true })
  );
};

export const randomTemplatReview = async (id: number) => {
  return fetcher<TemplateExample>(
    endPoints.randomReviewTemplate(id),
    makeOptions({ withAuth: true })
  );
};

export const listMessageTemplates = async (query?: MessageQuery) => {
  return fetcher<MessageTemplate[]>(
    endPoints.messageTemplateList(query),
    makeOptions({ withAuth: true })
  );
};

export const listMessage = async () => {
  return fetcher<MessageTemplate[]>(
    endPoints.messageTemplateList(),
    makeOptions({ withAuth: true })
  );
};

export const updateMessageTemplates = async (template: MessageTemplate) => {
  return fetcher<MessageTemplate>(
    endPoints.messageTemplate(!!template.id ? Number(template.id) : undefined),
    makeOptions({
      withAuth: true,
      method: template.id ? "PATCH" : "POST",
      payload: { ...template, id: undefined },
    })
  );
};

export const getTrackingFunctions = async () => {
  return fetcher<any>(
    endPoints.trackingFunction(),
    makeOptions({ withAuth: true })
  );
};

export const updateCampaignByIdFromTable = async (campaign: Campaign) => {
  return fetcher<Campaign>(
    endPoints.campaignById(campaign.id ?? campaign.campaign_id),
    makeOptions({
      withAuth: true,
      method: "PATCH",
      payload: { ...campaign, id: undefined },
    })
  );
};

export const listPushMessageTemplates = async () => {
  return fetcher<PushMessageTemplate[]>(
    endPoints.messagePools(),
    makeOptions({ withAuth: true })
  );
};

export const getmessagePoolsById = async (id: number | string) => {
  return fetcher<PushMessageTemplate>(
    endPoints.messagePoolsById(id),
    makeOptions({ withAuth: true })
  );
};

export const fetchPoolsDashboard = async ({
  endRow,
  startRow,
  filterModel,
  sortModel,
}: ServerSideRequest) =>
  fetcher<ServerSideResponse<PushMessageTemplate>>(
    endPoints.PoolsDashboard(),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel,
        sortModel,
      },
      method: "POST",
    })
  );

export const createPushMessageTemplate = async (query: PushMessageTemplate) => {
  return fetcher<PushMessageTemplate>(
    endPoints.createPushMessageTemplate(),
    makeOptions({ withAuth: true, method: "POST", payload: query })
  );
};

export const bulkDuplicationPushMessageTemplate = async (ids: any[]) =>
  fetcher<PushMessageTemplate[]>(
    endPoints.bulkDuplicationPushMessageTemplate(),
    makeOptions({
      withAuth: true,
      payload: ids,
      method: "POST",
    })
  );

export const bulkArchivePushMessageTemplate = async (ids: any[]) =>
  fetcher<any>(
    endPoints.bulkArchivePushMessageTemplate(),
    makeOptions({
      withAuth: true,
      payload: ids,
      method: "POST",
    })
  );

export const updatePushMessageTemplateById = async (
  id: number,
  pushMessageTemplate: PushMessageTemplate
) => {
  return fetcher<PushMessageTemplate>(
    endPoints.messagePoolsById(id),
    makeOptions({
      withAuth: true,
      method: "PATCH",
      payload: { ...pushMessageTemplate, id: undefined },
    })
  );
};

export const listImage = async (image_ids?: Array<number>) => {
  return fetcher<Array<ImageObj>>(
    endPoints.listImage(image_ids),
    makeOptions({ withAuth: true })
  );
};
export const getExportChannelSubscribers = async (
  query: exportChannelSubscriberQuery
) =>
  fetcher<AxiosResponse>(endPoints.exportsSubscribers(query), {
    ...makeOptions({ withAuth: true }),
    responseType: "blob",
  });

export const getIndicatorsAnalyticsData = async (query: AnalyticsQuery) =>
  fetcher<any>(
    endPoints.indicatorsAnalytics(query),
    makeOptions({ withAuth: true })
  );

export const fetchSubscribersSourcesDashboard = async ({
  endRow,
  startRow,
  filterModel,
  sortModel,
}: ServerSideRequest) =>
  fetcher<ServerSideResponse<SubscribersSource>>(
    endPoints.subscribersSourcesDashboard(),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel,
        sortModel,
      },
      method: "POST",
    })
  );

export const fetchWindowsPoolsDashboard = async ({
  endRow,
  startRow,
  filterModel,
  sortModel,
}: ServerSideRequest) =>
  fetcher<ServerSideResponse<WindowsPools>>(
    endPoints.WindowsPoolsDashboard(),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel,
        sortModel,
      },
      method: "POST",
    })
  );

export const getWindowsPoolsById = async (id: number | string) => {
  return fetcher<WindowsPools>(
    endPoints.windowsPoolsById(id),
    makeOptions({ withAuth: true })
  );
};

export const listAllChannels = async () => {
  return fetcher<Channel[]>(
    endPoints.listChannel(),
    makeOptions({ withAuth: true })
  );
};

export const getIndicatorsAnalytics = async (query: exportAnalyticsQuery) =>
  fetcher<AxiosResponse>(endPoints.exportsIndicatorsAnalytics(query), {
    ...makeOptions({ withAuth: true }),
    responseType: "blob",
  });

export const getWindowsPoolsAutocomplete = async () => {
  return fetcher<WindowsPools[]>(
    endPoints.WindowsPoolsAutocomplete(),
    makeOptions({ withAuth: true })
  );
};

export const createwindowsPools = async (query: WindowsPools) => {
  return fetcher<WindowsPools>(
    endPoints.createwindowsPools(),
    makeOptions({ withAuth: true, method: "POST", payload: query })
  );
};

export const updateWindowsPoolsById = async (
  id: number,
  windowsPools: WindowsPools
) => {
  return fetcher<WindowsPools>(
    endPoints.windowsPoolsById(id),
    makeOptions({
      withAuth: true,
      method: "PATCH",
      payload: { ...windowsPools, id: undefined },
    })
  );
};
export const listSubscribersSourceNames = async (channel_id: string | number) =>
  fetcher<SubscribersSourceList[]>(
    endPoints.listSubscribersSourceNames(channel_id),
    makeOptions({
      withAuth: true,
    })
  );

export const fetchWindowsPoolsAnalyticsDashboard = async (
  { endRow, startRow, filterModel, sortModel }: ServerSideRequest,
  report_id: string
) =>
  fetcher<ServerSideResponse<WindowsPoolsReport>>(
    endPoints.WindowsPoolsAnalyticsDashboard(report_id),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel,
        sortModel,
      },
      method: "POST",
    })
  );

export const updateWindowsPoolsWinnerById = async (
  windowsPoolsWinner: WindowsPoolsWinnerQuery
) => {
  return fetcher<WindowsPools>(
    windowsPoolsWinner?.banned !== undefined ||
      windowsPoolsWinner?.is_winner === undefined
      ? endPoints.WindowsPoolsBanned(
          windowsPoolsWinner.window_pool_id,
          windowsPoolsWinner.window_id
        )
      : endPoints.WindowsPoolsWinner(
          windowsPoolsWinner.window_pool_id,
          windowsPoolsWinner.window_id
        ),
    makeOptions({
      withAuth: true,
      method: "PATCH",
      payload: {
        is_winner: windowsPoolsWinner?.is_winner,
        banned: windowsPoolsWinner?.banned,
      },
    })
  );
};

export const bulkBannedWindowsPools = async (
  window_pool_id: string,
  ids: any[]
) =>
  fetcher<any>(
    endPoints.bulkBannedWindowsPools(window_pool_id),
    makeOptions({
      withAuth: true,
      payload: ids,
      method: "PATCH",
    })
  );

export const fetchSubscriptionWindowsDashboard = async ({
  endRow,
  startRow,
  filterModel,
  sortModel,
}: ServerSideRequest) =>
  fetcher<ServerSideResponse<SubscriptionWindow>>(
    endPoints.SubscriptionWindowsDashboard(),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel,
        sortModel,
      },
      method: "POST",
    })
  );

export const getSubscriptionWindowById = async (id: string | number) =>
  fetcher<SubscriptionWindow>(
    endPoints.SubscriptionWindowById(id),
    makeOptions({ withAuth: true })
  );

export const updateSubscriptionWindowById = async (
  id: number,
  subscriptionWindow: SubscriptionWindow
) =>
  fetcher<SubscriptionWindow>(
    endPoints.SubscriptionWindowById(id ?? subscriptionWindow.id),
    makeOptions({
      withAuth: true,
      payload: subscriptionWindow,
      method: "PATCH",
    })
  );

export const createSubscriptionWindow = async (
  subscriptionWindow: SubscriptionWindow
) =>
  fetcher<SubscriptionWindow>(
    endPoints.createSubscriptionWindow(),
    makeOptions({
      withAuth: true,
      payload: subscriptionWindow,
      method: "POST",
    })
  );

export const getCampaignsAnalyticsData = async (
  query: CampaignAnalyticsQuery
) =>
  fetcher<AxiosResponse>(
    endPoints.campaignsAnalytics(query),
    makeOptions({ withAuth: true })
  );

export const getUserInfo = async () =>
  fetcher<usersInfo>(endPoints.userInfo(), makeOptions({ withAuth: true }));

export const getUserQuotas = async () =>
  fetcher<userQuotas>(endPoints.userQuotas(), makeOptions({ withAuth: true }));

export const getUserSubscribersLimitations = async () =>
  fetcher<SubscribersLimitations>(
    endPoints.userSubscribersLimitations(),
    makeOptions({ withAuth: true })
  );

export const updateUserProfile = async (usersInfo: usersInfo) =>
  fetcher<usersInfo>(
    endPoints.userProfile(),
    makeOptions({
      withAuth: true,
      payload: usersInfo,
      method: "PATCH",
    })
  );

export const updateUserPassword = async (usersPassword: usersPassword) =>
  fetcher<usersPassword>(
    endPoints.userPassword(),
    makeOptions({
      withAuth: true,
      payload: usersPassword,
      method: "PATCH",
    })
  );

export const getUserPasswordVerify = async (password: string) =>
  fetcher<AxiosResponse>(
    endPoints.userPasswordVerify(),
    makeOptions({
      withAuth: true,
      method: "PUT",
      payload: { password: password },
    })
  );

export const listNotifications = async (page: number) => {
  return fetcher<notificationInfiniteScroll>(
    endPoints.listNotifications(page),
    makeOptions({ withAuth: true })
  );
};

export const notificationsCount = async () => {
  return fetcher<notificationCount>(
    endPoints.notificationsCount(),
    makeOptions({ withAuth: true })
  );
};

export const updateNotificatioStatus = async (
  queryNotificationStatus: notificationStatusObj
) =>
  fetcher<AxiosResponse>(
    endPoints.notificationsStatus(),
    makeOptions({
      withAuth: true,
      method: "PUT",
      payload: {
        channel_notification_ids:
          queryNotificationStatus.channel_notification_ids,
        mark_all: queryNotificationStatus.mark_all,
        status: "READ",
      },
    })
  );

export const UserResetPassword = async (email: string) =>
  fetcher<any>(
    endPoints.userResetPassword(),
    makeOptions({
      withAuth: true,
      payload: { email: email },
      method: "POST",
    })
  );

export const updateNewPassword = async (query: ResetPasswordQuery) =>
  fetcher<any>(
    endPoints.userSetNewPassword(query),
    makeOptions({
      withAuth: true,
      payload: {
        new_password: query.new_password,
        confirmation_password: query.confirmation_password,
      },
      method: "POST",
    })
  );

export const fetchContentInsightsDashboard = async (
  { endRow, startRow, filterModel, sortModel }: ServerSideRequest,
  id: string
) =>
  fetcher<ServerSideResponse<ContentInsights>>(
    endPoints.contentInsightsDashboard(id),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel,
        sortModel,
      },
      method: "POST",
    })
  );

export const getMessagePoolReivew = async (id: number) => {
  return fetcher<TemplateExample>(
    endPoints.messageExamples(id),
    makeOptions({ withAuth: true })
  );
};

export const getCampaignAnalytics = async (
  query: exportCampaignAnalyticsQuery
) =>
  fetcher<AxiosResponse>(endPoints.exportsCampaignAnalytics(query), {
    ...makeOptions({ withAuth: true }),
    responseType: "blob",
  });

export const getContentInsights = async (query: exportContentQuery) =>
  fetcher<AxiosResponse>(endPoints.exportsContentInsights(query), {
    ...makeOptions({ withAuth: true }),
    responseType: "blob",
  });

export const getSubscriptionWindowAutocomplete = async () => {
  return fetcher<SubscriptionWindow[]>(
    endPoints.SubscriptionWindowAutocomplete(),
    makeOptions({ withAuth: true })
  );
};

export const randomWindowsPoolsReview = async () => {
  return fetcher<WindowsPools>(
    endPoints.WindowsPoolsPickRandom(),
    makeOptions({ withAuth: true })
  );
};

export const deleteChannel = async (id: string) =>
  fetcher<Channel>(
    endPoints.channelById(id),
    makeOptions({
      withAuth: true,
      method: "DELETE",
    })
  );

export const deleteWindowsPoolsById = async (id: string) =>
  fetcher<Channel>(
    endPoints.windowsPoolsById(id),
    makeOptions({
      withAuth: true,
      method: "DELETE",
    })
  );

export const bulkDuplicationWindowsPools = async (ids: any[]) =>
  fetcher<WindowsPools[]>(
    endPoints.bulkDuplicationWindowsPools(),
    makeOptions({
      withAuth: true,
      payload: ids,
      method: "POST",
    })
  );

export const bulkArchiveWindowsPools = async (ids: any[]) =>
  fetcher<any>(
    endPoints.bulkArchiveWindowsPools(),
    makeOptions({
      withAuth: true,
      payload: ids,
      method: "POST",
    })
  );

export const listSegments = async (query?: SegmentsSearch) =>
  fetcher<Segments[]>(
    endPoints.listSegments(query),
    makeOptions({
      withAuth: true,
    })
  );

export const listAllSegments = async () => {
  return fetcher<Segments[]>(
    endPoints.listSegments(),
    makeOptions({ withAuth: true })
  );
};

export const fetchSegmentsDashboard = async ({
  endRow,
  startRow,
  filterModel,
  sortModel,
}: ServerSideRequest) =>
  fetcher<ServerSideResponse<Segments>>(
    endPoints.SegmentsDashboard(),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel,
        sortModel,
      },
      method: "POST",
    })
  );
export const getWindowsPreviewReadById = async (id: string | number) => {
  return fetcher<WindowsPreview>(
    endPoints.windowsPreviewReadById(id),
    makeOptions({ withAuth: true })
  );
};

export const getChannelCodeSnippet = async (token: string) =>
  fetcher<ChannelDetail>(
    endPoints.channelCodeSnippet(),
    makeOptions({ withAuth: true, headerParameter: { "Channel-Token": token } })
  );

export const getSegmentsAutocomplete = async () => {
  return fetcher<Segments[]>(
    endPoints.SegmentsAutocomplete(),
    makeOptions({ withAuth: true })
  );
};
export const getSearchSegments = async (query?: SegmentsSearch) => {
  return fetcher<Segments[]>(
    endPoints.SegmentsAutocomplete(),
    makeOptions({ withAuth: true })
  );
};

export const getABTestReport = async (query: exportABtestReportQuery) =>
  fetcher<AxiosResponse>(endPoints.exportsABTestReport(query), {
    ...makeOptions({ withAuth: true }),
    responseType: "blob",
  });

export const getSegmentById = async (id: string | number) =>
  fetcher<Segments>(endPoints.segmentById(id), makeOptions({ withAuth: true }));

export const createSegment = async (segment: Segments) =>
  fetcher<Segments>(
    endPoints.createSegment(),
    makeOptions({
      withAuth: true,
      payload: segment,
      method: "POST",
    })
  );

export const updateSegment = async (segment: Segments) =>
  fetcher<Segments>(
    endPoints.segmentById(segment.id),
    makeOptions({
      withAuth: true,
      payload: segment,
      method: "PUT",
    })
  );

export const deleteSegment = async (id: string) =>
  fetcher<Segments>(
    endPoints.segmentById(id),
    makeOptions({
      withAuth: true,
      method: "DELETE",
    })
  );

export const updateSubscribersParamsById = async (
  id: string | number,
  subscribersParams: string[]
) =>
  fetcher<string[]>(
    endPoints.subscribersParamsById(),
    makeOptions({
      withAuth: true,
      payload: { channel_id: id, parameters_data: subscribersParams },
      method: "PUT",
    })
  );

export const getSubscribersParamsById = async (id: string | number) =>
  fetcher<string[]>(
    endPoints.subscribersParamsById(id),
    makeOptions({ withAuth: true })
  );

export const fetchWindowPoolsContentInsightsDashboard = async (
  { endRow, startRow, filterModel, sortModel }: ServerSideRequest,
  push_pools_id: string
) =>
  fetcher<ServerSideResponse<WindowPoolsContentInsights>>(
    endPoints.windowPoolscontentInsightsDashboard(push_pools_id),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel,
        sortModel,
      },
      method: "POST",
    })
  );

export const CheckoutPayment = async (price_lookup?: string) =>
  fetcher<{ redirect_url: string }>(
    endPoints.paymentsCheckout(),
    makeOptions({
      withAuth: true,
      payload: { price_lookup: price_lookup ?? "base_subscription" },
      method: "POST",
    })
  );

export const UpdateCheckoutPayment = async () =>
  fetcher<{ redirect_url: string }>(
    endPoints.paymentsCheckout(),
    makeOptions({
      withAuth: true,
      method: "PATCH",
    })
  );

export const getCheckoutPaymentValidate = async (session_id: string | number) =>
  fetcher<UserPayment[]>(
    endPoints.paymentsCheckoutValidate(session_id),
    makeOptions({ withAuth: true })
  );

export const getAvailablePricesList = async () =>
  fetcher<Prices[]>(
    endPoints.availablePricesList(),
    makeOptions({ withAuth: true })
  );

export const getUserSubscriptionDetails = async () =>
  fetcher<UserSubscription>(
    endPoints.userSubscriptionDetails(),
    makeOptions({ withAuth: true })
  );

export const UpdateUsersSubscriptionPlan = async (price_lookup: string) =>
  fetcher<any>(
    endPoints.userSubscriptionDetails(),
    makeOptions({
      withAuth: true,
      payload: { price_lookup: price_lookup },
      method: "PATCH",
    })
  );

export const CancelUsersSubscriptionPlan = async () =>
  fetcher<any>(
    endPoints.userSubscriptionDetails(),
    makeOptions({
      withAuth: true,
      method: "DELETE",
    })
  );

export const getUserInvoices = async () =>
  fetcher<UserInvoices[]>(
    endPoints.userInvoices(),
    makeOptions({ withAuth: true })
  );

export const getDownloadWebSdk = async () =>
  fetcher<AxiosResponse>(endPoints.downloadWebSdk(), {
    ...makeOptions({ withAuth: true }),
    responseType: "blob",
  });

export const getSubscribersTotal = async () =>
  fetcher<any>(endPoints.subscribersTotal(), makeOptions({ withAuth: true }));

export const getChannelPWA = async (channel: Channel) =>
  fetcher<AxiosResponse>((API_MANAGER_URL ?? "") + endPoints.channelPWA(), {
    ...makeOptions({
      withAuth: true,
      method: "POST",
      headerParameter: { "Channel-Token": channel.channel_token },
    }),
    responseType: "blob",
  });

export const fetchFirebaseAccountsDashboard = async ({
  endRow,
  startRow,
  filterModel,
  sortModel,
}: ServerSideRequest) =>
  fetcher<ServerSideResponse<FirebaseAccounts>>(
    endPoints.FirebaseAccountsDashboard(),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel,
        sortModel,
      },
      method: "POST",
    })
  );

export const getUserFirebaseCredentialsList = async () =>
  fetcher<FirebaseAccounts[]>(
    endPoints.userFirebaseCredentialsList(),
    makeOptions({ withAuth: true })
  );

export const createFirebaseAccounts = async (
  firebaseAccounts: FirebaseAccounts
) => {
  const formData = new FormData();
  if (firebaseAccounts) {
    if (firebaseAccounts.credentials_json_file) {
      formData.append(
        "credentials_json",
        firebaseAccounts.credentials_json_file[0]
      );
    }

    if (firebaseAccounts.name) {
      formData.append("name", firebaseAccounts.name);
    }
    if (firebaseAccounts.uuid) {
      formData.append("uuid", firebaseAccounts.uuid);
    }
    if (firebaseAccounts.sender_id) {
      formData.append("sender_id", firebaseAccounts.sender_id.toString());
    }
    if (firebaseAccounts.server_key) {
      formData.append("server_key", firebaseAccounts.server_key);
    }
    if (firebaseAccounts.public_key) {
      formData.append("public_key", firebaseAccounts.public_key);
    }
  }

  return fetcher<any>(
    endPoints.firebaseAccounts(),
    makeOptions({
      withAuth: true,
      payload: formData,
      method: "POST",
      contentType: "multipart/form-data",
    })
  );
};

export const UpdateFirebaseAccounts = async (
  firebaseAccounts: FirebaseAccounts
) => {
  const formData = new FormData();
  if (firebaseAccounts) {
    if (firebaseAccounts.credentials_json_file) {
      formData.append(
        "credentials_json",
        firebaseAccounts.credentials_json_file[0]
      );
    }

    if (firebaseAccounts.name) {
      formData.append("name", firebaseAccounts.name);
    }
    if (firebaseAccounts.uuid) {
      formData.append("uuid", firebaseAccounts.uuid);
    }
    if (firebaseAccounts.sender_id) {
      formData.append("sender_id", firebaseAccounts.sender_id.toString());
    }
    if (firebaseAccounts.server_key) {
      formData.append("server_key", firebaseAccounts.server_key);
    }
    if (firebaseAccounts.public_key) {
      formData.append("public_key", firebaseAccounts.public_key);
    }
  }

  return fetcher<any>(
    endPoints.firebaseAccountsById(firebaseAccounts.id),
    makeOptions({
      withAuth: true,
      payload: formData,
      method: "PATCH",
      contentType: "multipart/form-data",
    })
  );
};

export const DeleteFirebaseAccounts = async (id: number) =>
  fetcher<any>(
    endPoints.firebaseAccountsById(id),
    makeOptions({
      withAuth: true,
      method: "DELETE",
    })
  );

export const getFirebaseAccountsById = async (id: string | number) =>
  fetcher<any>(
    endPoints.firebaseAccountsById(id),
    makeOptions({
      withAuth: true,
    })
  );

// archive
export const archiveChannel = async (id: string | number) =>
  fetcher<Channel>(
    endPoints.archiveChannelById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const unArchiveChannel = async (id: string | number) =>
  fetcher<Channel>(
    endPoints.unArchiveChannelById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const archiveCampaign = async (id: string | number) =>
  fetcher<any>(
    endPoints.archiveCampaignsById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const unArchiveCampaign = async (id: string | number) =>
  fetcher<any>(
    endPoints.unArchiveCampaignsById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const archiveSegment = async (id: string | number) =>
  fetcher<any>(
    endPoints.archiveSegmentById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const unArchiveSegment = async (id: string | number) =>
  fetcher<any>(
    endPoints.unArchiveSegmentById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const archivePushNotification = async (id: string | number) =>
  fetcher<any>(
    endPoints.archivePushNotificationById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const unArchivePushNotification = async (id: string | number) =>
  fetcher<any>(
    endPoints.unArchivePushNotificationById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const archiveWindowsPools = async (id: string | number) =>
  fetcher<any>(
    endPoints.archiveWindowsPoolsById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const unArchiveWindowsPools = async (id: string | number) =>
  fetcher<any>(
    endPoints.unArchiveWindowsPoolsById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const deleteCampaign = async (id: string | number) =>
  fetcher<Campaign>(
    endPoints.campaignById(id),
    makeOptions({
      withAuth: true,
      method: "DELETE",
    })
  );

export const deletePushMessageTemplateById = async (id: string | number) => {
  return fetcher<PushMessageTemplate>(
    endPoints.messagePoolsById(id),
    makeOptions({
      withAuth: true,
      method: "DELETE",
    })
  );
};

export const poolOptimisation = async (
  id: string | number,
  payload: PoolOptimisationConfig
) =>
  fetcher<any>(
    endPoints.poolOptimisationByCampaignId(id),
    makeOptions({
      withAuth: true,
      method: "PUT",
      payload: payload,
    })
  );

export const getPoolOptimisationById = async (id: string | number) =>
  fetcher<ContentInsightsFavroites>(
    endPoints.poolOptimisationByCampaignId(id),
    makeOptions({
      withAuth: true,
    })
  );

export const getEventsById = async (id: string | number) =>
  fetcher<EventObject>(
    endPoints.eventsById(id),
    makeOptions({ withAuth: true })
  );

export const createEvents = async (eventObject: EventObject) =>
  fetcher<EventObject>(
    endPoints.createEvents(),
    makeOptions({
      withAuth: true,
      payload: eventObject,
      method: "POST",
    })
  );

export const updateEvents = async (eventObject: EventObject) =>
  fetcher<EventObject>(
    endPoints.eventsById(eventObject.id),
    makeOptions({
      withAuth: true,
      payload: eventObject,
      method: "PUT",
    })
  );

export const listAllEvents = async () => {
  return fetcher<EventObject[]>(
    endPoints.listEvents(),
    makeOptions({ withAuth: true })
  );
};

export const fetchEventsDashboard = async ({
  endRow,
  startRow,
  filterModel,
  sortModel,
}: ServerSideRequest) =>
  fetcher<ServerSideResponse<EventObject>>(
    endPoints.eventsDashboard(),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel: convertDateModel(filterModel),
        sortModel,
      },
      method: "POST",
    })
  );

export const getEventsAnalyticsData = async (query: EventsAnalyticsQuery) =>
  fetcher<any>(
    endPoints.eventsAnalytics(query),
    makeOptions({ withAuth: true })
  );

export const getEventsAnalytics = async (query: exportEventsAnalyticsQuery) =>
  fetcher<AxiosResponse>(endPoints.exportsCampaignAnalytics(query), {
    ...makeOptions({ withAuth: true }),
    responseType: "blob",
  });

export const updateJourneyName = async (journey: Journey) => undefined;
// fetcher<usersInfo>(
//   endPoints.userProfile(),
//   makeOptions({
//     withAuth: true,
//     payload: usersInfo,
//     method: "PATCH",
//   })
// );
export const fetchJourneyDashboard = async ({
  endRow,
  startRow,
  filterModel,
  sortModel,
}: ServerSideRequest) =>
  fetcher<ServerSideResponse<Journey>>(
    endPoints.JourneyDashboard(),
    makeOptions({
      withAuth: true,
      payload: {
        startRow,
        endRow,
        filterModel,
        sortModel,
      },
      method: "POST",
    })
  );

export const getJourneyAutocomplete = async () => {
  return fetcher<Journey[]>(
    endPoints.createJourney(),
    makeOptions({ withAuth: true })
  );
};

export const createJourney = async (journey: Journey) =>
  fetcher<Journey>(
    endPoints.createJourney(),
    makeOptions({
      withAuth: true,
      payload: journey,
      method: "POST",
    })
  );

export const getJourneyById = async (id: string | number) =>
  fetcher<Journey>(endPoints.journeyById(id), makeOptions({ withAuth: true }));

export const getJourneyElementsStructure = async (id: string | number) =>
  fetcher<JourneyElements[]>(
    endPoints.journeyElementsById(id),
    makeOptions({ withAuth: true })
  );

export const updateJourney = async (journeyObject: Journey) =>
  fetcher<Journey>(
    endPoints.journeyById(journeyObject.id),
    makeOptions({
      withAuth: true,
      payload: journeyObject,
      method: "PATCH",
    })
  );

export const deleteJourney = async (journeyObject: Journey) =>
  fetcher<Journey>(
    endPoints.journeyById(journeyObject.id),
    makeOptions({
      withAuth: true,
      method: "DELETE",
    })
  );

export const archiveJourney = async (id: string) =>
  fetcher<Journey>(
    endPoints.archiveJourneyById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const duplicationJourney = async (id: string) =>
  fetcher<Journey>(
    endPoints.duplicationJourneyById(id),
    makeOptions({
      withAuth: true,
      method: "POST",
    })
  );

export const updateJourneyElementsById = async (
  journeyElementsObject: JourneyElements
) =>
  fetcher<JourneyElements>(
    endPoints.journeyElementsById(
      journeyElementsObject.journey,
      journeyElementsObject.id
    ),
    makeOptions({
      withAuth: true,
      payload: journeyElementsObject,
      method: "PATCH",
    })
  );

export const createJourneyElementsById = async (
  journeyElementsObject: JourneyElements
) =>
  fetcher<JourneyElements>(
    endPoints.journeyElementsById(
      journeyElementsObject.journey,
      journeyElementsObject.id
    ),
    makeOptions({
      withAuth: true,
      payload: journeyElementsObject,
      method: "POST",
    })
  );

export const deleteJourneyElementsById = async (
  journeyElementsObject: JourneyElements
) =>
  fetcher<any>(
    endPoints.journeyElementsById(
      journeyElementsObject.journey,
      journeyElementsObject.id
    ),
    makeOptions({
      withAuth: true,
      method: "DELETE",
    })
  );

export const resendVerificationEmailApi = async () =>
  fetcher<AuthResponse>(
    endPoints.resendEmail(),
    makeOptions({ withAuth: true, method: "POST" })
  );

export const getPostbackForChannel = async (channelToken?: string) =>
  fetcher<PostbackEcho>(
    endPoints.postBackEcho(),
    makeOptions({
      withAuth: true,
      headerParameter: { "Channel-Token": channelToken },
    })
  );

export const createPostbackEcho = async (
  channelToken: string,
  postback: PostbackEcho
) =>
  fetcher<PostbackEcho>(
    endPoints.createPostBackEcho(),
    makeOptions({
      withAuth: true,
      payload: postback,
      method: "POST",
      headerParameter: { "Channel-Token": channelToken },
    })
  );

export const updatePostbackEcho = async (
  channelToken: string,
  postback: PostbackEcho
) => {
  return fetcher<PostbackEcho>(
    endPoints.postBackById(postback.id),
    makeOptions({
      withAuth: true,
      payload: postback,
      method: "PATCH",
      headerParameter: { "Channel-Token": channelToken },
    })
  );
};

export const deletePostbackEcho = async (channelToken: string, id: number) => {
  return fetcher<PostbackEcho>(
    endPoints.postBackById(id),
    makeOptions({
      withAuth: true,
      method: "DELETE",
      headerParameter: { "Channel-Token": channelToken },
    })
  );
};
