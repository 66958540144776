import { ListButtons, ObjectList, WindowsPools } from "../types/windowsPools";
import { UseFormMethods } from "react-hook-form";
import { difference } from "./channelServices";
import { isEmpty } from "lodash";
import { registerField } from "./forms";

export const registerWindowsPoolsFields = (
  data: WindowsPools | undefined,
  form: UseFormMethods<Record<string, any>>,
  uID: string | undefined
) => {
  form.reset(data);
  if (data?.id) {
    registerField(form, "id", data?.id);
  }
  registerField(form, "name", data?.name ?? "");
  registerField(form, "images", data?.images ?? []);
  registerField(
    form,
    "action_messages",
    convertDataObject(data?.action_messages)
  );
  registerField(
    form,
    "cancel_buttons",
    data?.cancel_buttons ?? [
      {
        value: "",
        styles: {
          cancel_text_color: "#000000",
          cancel_button_color: "#FFFFFF",
        },
      },
    ]
  );
  registerField(
    form,
    "confirm_buttons",
    data?.confirm_buttons ?? [
      {
        value: "",
        styles: {
          confirm_button_color: "#2B67E8",
          confirm_text_color: "#FFFFFF",
        },
      },
    ]
  );
  registerField(form, "status", data?.status ?? "draft");
  registerField(form, "channel_ids", data?.channel_ids ?? []);
  registerField(form, "content_mix", data?.content_mix);
  if (data?.images) {
    registerField(
      form,
      "imagesTmp",
      data?.images.map((item) => item.value)
    );
  } else {
    registerField(form, "imagesTmp", []);
  }
  if (uID) {
    registerField(form, "segment.payload_json", uID);
    registerField(form, "segment.id", uID);
  } else if (data?.channel_ids && data?.channel_ids.length > 0) {
    registerField(form, "segment.payload_json", data?.channel_ids[0]);
    registerField(form, "segment.id", data?.channel_ids[0]);
  } else {
    registerField(form, "segment.name", "  ");
    registerField(form, "segment.id", "");
  }
};

export const convertListButton = (data: ListButtons[]) => {
  return {
    confirm_buttons: data.map((item) => item.confirm_buttons),
    cancel_buttons: data.map((item) => item.cancel_buttons),
  };
};

export const mergeListButton = (
  confirmButtons: ObjectList[],
  cancelButtons: ObjectList[]
) => {
  let tmpArray = [];
  for (let i = 0; i < confirmButtons.length; i++) {
    tmpArray.push({
      confirm_buttons: confirmButtons[i].value,
      cancel_buttons: cancelButtons[i].value,
    });
  }
  if (tmpArray.length > 0) {
    return tmpArray;
  }
  return [{ confirm_buttons: "", cancel_buttons: "" }];
};

export const randomTemplate = (data: WindowsPools | undefined) => {
  if (
    !!data &&
    !!data.cancel_buttons &&
    !!data.images &&
    !!data.action_messages &&
    !!data.cancel_buttons
  ) {
    if (
      data.cancel_buttons.length > 0 &&
      data.images.length > 0 &&
      data.action_messages.length > 0
    ) {
      let index = Math.floor(Math.random() * data.cancel_buttons.length);
      let indexImg = Math.floor(Math.random() * data.images.length);
      let indexMsg = Math.floor(Math.random() * data.action_messages.length);
      return {
        message:
          data.action_messages[indexMsg]?.value ??
          data.action_messages[indexMsg],
        image: data.images[indexImg].url ?? "",
        cancel_buttons: data.cancel_buttons[index],
        confirm_buttons: data.confirm_buttons[index],
      };
    }
  }
  return null;
};

export const checkChangeSave = (
  windowsPools: WindowsPools,
  originWindowsPools: WindowsPools | undefined,
  isDraft: boolean
) => {
  if (!!originWindowsPools) {
    const pureWindowsPools = makePurePushMessageTemplate(
      windowsPools,
      originWindowsPools
    ) as WindowsPools;

    const newPushMessageTemplate = provideDefaultValue(
      pureWindowsPools,
      originWindowsPools,
      isDraft
    );

    return newPushMessageTemplate;
  }
  return null;
};

export const makePurePushMessageTemplate = (
  object: WindowsPools,
  base: WindowsPools | undefined
) => {
  if (base) {
    const diffObject = difference(object, base);
    return diffObject;
  }
  return object;
};

export const provideDefaultValue = (
  object: WindowsPools,
  base: WindowsPools,
  isDraft: boolean
): WindowsPools => {
  const result = {
    ...object,
    status: isDraft
      ? "draft"
      : base.status === "draft"
      ? "in_progress"
      : base.status,
  } as any;

  Object.keys(result).forEach(
    (key) => result[key] === undefined && delete result[key]
  );
  return result;
};

export const convertList = (data?: number[] | string[] | ObjectList[]) => {
  let arrayTmp = [];
  if (data) {
    for (const item in data) {
      if (!isEmpty(data[item]) || typeof data[item] === "number") {
        arrayTmp.push({ value: data[item] });
      }
    }
  }
  return arrayTmp;
};

export const convertDataObject = (data?: ObjectList[]) => {
  let arrayTmp = [];
  if (data) {
    for (const item in data) {
      if (!isEmpty(data[item])) {
        arrayTmp.push(data[item].value);
      }
    }
  }
  return arrayTmp;
};

export const convertWindowsPools = (
  windowsPools: WindowsPools,
  channel_ids?: number[]
) => {
  let baseWindowsPools = {
    name: windowsPools.name,
    channel_ids: channel_ids
      ? channel_ids
      : windowsPools?.segment?.id
      ? [windowsPools?.segment?.id]
      : undefined,
    action_messages: convertList(windowsPools.action_messages),
    cancel_buttons: windowsPools.cancel_buttons.filter(function (el) {
      return el.value !== "";
    }),
    confirm_buttons: windowsPools.confirm_buttons.filter(function (el) {
      return el.value !== "";
    }),
    images: convertList(windowsPools.images.map((item) => item.value)),
    content_mix: windowsPools.content_mix,
  } as WindowsPools;
  return baseWindowsPools;
};
