import {
  AuthResponse,
  Prices,
  UserPayment,
  UserSubscription,
} from "../types/auth";
import {
  Campaign,
  CampaignAnalytics,
  MessageQuery,
  MessageTemplate,
  PoolOptimisationConfig,
  Template,
  TemplateExample,
} from "../types/campaign";
import {
  CancelUsersSubscriptionPlan,
  CheckoutPayment,
  DeleteFirebaseAccounts,
  UpdateCheckoutPayment,
  UpdateFirebaseAccounts,
  UpdateUsersSubscriptionPlan,
  UserResetPassword,
  archiveCampaign,
  archiveChannel,
  archiveJourney,
  archivePushNotification,
  archiveSegment,
  archiveWindowsPools,
  bulkArchiveCampaign,
  bulkArchiveChannel,
  bulkArchivePushMessageTemplate,
  bulkArchiveWindowsPools,
  bulkBannedWindowsPools,
  bulkDuplicationCampaign,
  bulkDuplicationChannel,
  bulkDuplicationPushMessageTemplate,
  bulkDuplicationWindowsPools,
  bulkStopCampaign,
  createCampaign,
  createChannel,
  createEvents,
  createFirebaseAccounts,
  createJourney,
  createJourneyElementsById,
  createPageConfig,
  createPostbackEcho,
  createPushMessageTemplate,
  createSegment,
  createSubscriptionWindow,
  createwindowsPools,
  deleteCampaign,
  deleteChannel,
  deleteJourney,
  deleteJourneyElementsById,
  deletePostbackEcho,
  deletePushMessageTemplateById,
  deleteSegment,
  duplicationChannel,
  duplicationJourney,
  fetchCampaignsAnalytics,
  fetchCampaignsDashboard,
  fetchPoolsDashboard,
  fetchSubscribersSourcesDashboard,
  fetchSubscriptionWindowsDashboard,
  fetchWindowsPoolsDashboard,
  getABTestReport,
  getAvailablePricesList,
  getCampaignAnalytics,
  getCampaignById,
  getCampaignsAnalyticsData,
  getChannelById,
  getChannelCodeSnippet,
  getChannelPWA,
  getChannelSubscribers,
  getCheckoutPaymentValidate,
  getContentInsights,
  getDownloadWebSdk,
  getEventsAnalytics,
  getEventsAnalyticsData,
  getEventsById,
  getExportChannelSubscribers,
  getFirebaseAccountsById,
  getIndicatorsAnalytics,
  getIndicatorsAnalyticsData,
  getJourneyAutocomplete,
  getJourneyById,
  getJourneyElementsStructure,
  getMessagePoolReivew,
  getPoolOptimisationById,
  getPostbackForChannel,
  getSearchSegments,
  getSegmentById,
  getSegmentsAutocomplete,
  getSubscribersParamsById,
  getSubscribersTotal,
  getSubscriptionWindowAutocomplete,
  getSubscriptionWindowById,
  getTrackingFunctions,
  getUserFirebaseCredentialsList,
  getUserInfo,
  getUserInvoices,
  getUserPasswordVerify,
  getUserQuotas,
  getUserSubscribersLimitations,
  getUserSubscriptionDetails,
  getWindowsPoolsAutocomplete,
  getWindowsPoolsById,
  getmessagePoolsById,
  listAllChannels,
  listAllEvents,
  listCampaigns,
  listChannel,
  listImage,
  listMessage,
  listMessageTemplates,
  listPageConfig,
  listPushMessageTemplates,
  listSegments,
  listSubscribersSourceNames,
  listTemplates,
  loginApi,
  notificationsCount,
  poolOptimisation,
  randomTemplatReview,
  randomWindowsPoolsReview,
  refreshNewTokenApi,
  resendVerificationEmailApi,
  unArchiveCampaign,
  unArchiveChannel,
  unArchivePushNotification,
  unArchiveSegment,
  unArchiveWindowsPools,
  updateCampaignById,
  updateCampaignByIdFromTable,
  updateChannel,
  updateEvents,
  updateJourney,
  updateJourneyElementsById,
  updateJourneyName,
  updateMessageTemplates,
  updateNewPassword,
  updateNotificatioStatus,
  updatePageConfig,
  updatePostbackEcho,
  updatePushMessageTemplateById,
  updateSegment,
  updateSubscribersParamsById,
  updateSubscriptionWindowById,
  updateUserPassword,
  updateUserProfile,
  updateWindowsPoolsById,
  updateWindowsPoolsWinnerById,
  uploadImage,
  verifyCodeSnippet,
} from "./api";
import {
  Channel,
  ChannelDetail,
  ChannelSubscriberResponse,
  PostbackEcho,
  SnippetVerifyResponse,
  SubscriptionWindow,
  channelSearch,
} from "../types/channel";
import {
  ContentInsightsFavroites,
  exportContentQuery,
} from "../types/contentInsights";
import { EventObject, exportEventsAnalyticsQuery } from "../types/events";
import { FirebaseAccounts } from "../types/firebaseAccounts";
import { ImageObj, PushMessageTemplate } from "../types/pushNotification";
import { Journey, JourneyElements } from "../types/journey";
import {
  ResetPasswordQuery,
  SubscribersLimitations,
  UserInvoices,
  userQuotas,
  usersInfo,
  usersPassword,
} from "../types/userInfo";
import { Segments, SegmentsSearch } from "../types/segments";
import { ServerSideResponse } from "../types/query";
import {
  SubscribersSource,
  SubscribersSourceList,
} from "../types/subscribersSource";
import { UserPageConfig } from "../types/dashboard";
import {
  WindowsPools,
  WindowsPoolsWinnerQuery,
  WindowsPreview,
  exportABtestReportQuery,
} from "../types/windowsPools";
import { convertChannelDetailToChannel } from "./channelServices";
import {
  deleteWindowsPoolsById,
  getWindowsPreviewReadById,
  listAllSegments,
} from "./api";
import { exportAnalyticsQuery } from "../types/indicatorsAnalytics";
import { exportCampaignAnalyticsQuery } from "../types/campaignAnalytics";
import {
  notificationCount,
  notificationStatusObj,
} from "../types/notification";
import { useMutation, useQuery } from "react-query";

type QueryCallback<T> = {
  onSuccess?: (data?: T) => void;
  onError?: (message: any, variable?: any) => void;
  ignoreLoading?: boolean;
};

export const useChannel = (
  id?: string | number,
  callback?: QueryCallback<Channel | ChannelDetail>,
  isConvertToChannel = true
) => {
  return useQuery(
    ["channels", id],
    async () => {
      if (id) {
        const channelDetail = await getChannelById(id);
        if (isConvertToChannel) {
          const channel = convertChannelDetailToChannel(channelDetail);
          return channel;
        }
        return channelDetail;
      }
    },
    { onSuccess: callback?.onSuccess, refetchOnWindowFocus: false }
  );
};

export const useGetsourceLinkParams = ({
  onSuccess,
  ignoreLoading,
}: QueryCallback<string[]>) => {
  return useMutation(
    async (id: string) => {
      if (id) {
        const params = await getSubscribersParamsById(id);
        return params ?? [];
      }
    },
    {
      mutationKey: ignoreLoading ? "ignoreMutation" : undefined,
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useUpdateChannel = (callback?: QueryCallback<Channel>) => {
  return useMutation(
    ({ channel }: { channel: Channel; callback?: () => void }) =>
      updateChannel(channel),
    {
      onSuccess: (data, variables) => {
        variables.callback && variables.callback();
        callback?.onSuccess && callback.onSuccess(data);
      },
      mutationKey: callback?.ignoreLoading ? "ignoreMutation" : undefined,
    }
  );
};

export const useCreateChannel = ({
  onSuccess,
  onError,
}: QueryCallback<Channel>) => {
  return useMutation((channel: Channel) => createChannel(channel), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError: (error: { message: string; code: string }) =>
      onError && onError(error.message, error.code),
  });
};

export const useDuplicationChannel = ({
  onSuccess,
  onError,
}: QueryCallback<Channel>) => {
  return useMutation((channel: Channel) => duplicationChannel(channel), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useBulkDuplicationChannel = ({
  onSuccess,
  onError,
}: QueryCallback<Channel[]>) => {
  return useMutation((ids: any[]) => bulkDuplicationChannel(ids), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useBulkArchiveChannel = ({
  onSuccess,
  onError,
}: QueryCallback<Channel[]>) => {
  return useMutation((ids: any[]) => bulkArchiveChannel(ids), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useSearchChannel = ({
  onSuccess,
  ignoreLoading,
}: QueryCallback<Channel[]>) => {
  return useMutation(
    ({ searchString, is_used_campaigns, status, exclude }: channelSearch) =>
      listChannel({
        name: searchString,
        status: status,
        is_used_campaigns: is_used_campaigns,
        exclude: exclude,
      }),
    {
      mutationKey: ignoreLoading ? "ignoreMutation" : undefined,
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useUploadImage = ({
  onSuccess,
  onError,
}: QueryCallback<ImageObj>) => {
  return useMutation((file: FormData) => uploadImage(file), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useSnippetVerification = ({
  onSuccess,
  onError,
}: QueryCallback<SnippetVerifyResponse>) => {
  return useMutation(
    ({ id, path }: { id: string; path: string }) =>
      verifyCodeSnippet(
        id,
        path !== "/" && path.endsWith("/")
          ? path.substr(0, path.length - 1)
          : path
      ),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      onError: (error: { message: string }) =>
        onError && onError(error.message),
    }
  );
};

export const useChannelSubscribers = (
  id: string,
  startTime: string | undefined,
  endTime: string | undefined,
  { onSuccess }: QueryCallback<ChannelSubscriberResponse>
) => {
  return useQuery(
    ["channel-subscriber", Number(id), startTime, endTime],
    () =>
      getChannelSubscribers({
        channels: id,
        start: startTime,
        end: endTime,
        accumulative: 1,
      }),
    {
      onSuccess: (data) => onSuccess && onSuccess(data),
      refetchOnWindowFocus: false,
    }
  );
};

export const useListPageConfig = (
  token: string,
  callback?: QueryCallback<UserPageConfig[]>
) => {
  const { onSuccess } = callback ?? {};
  return useQuery(["list-page-config"], () => listPageConfig(token), {
    enabled: !!token,
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus: false,
    cacheTime: 600000,
    staleTime: 600000,
  });
};

export const usePageConfig = (
  pageName: string,
  callback?: QueryCallback<UserPageConfig>
) => {
  const { onSuccess } = callback ?? {};
  return useQuery(["get-page-config"], () => listPageConfig(), {
    onSuccess: (data) =>
      onSuccess && onSuccess(data.find((config) => config.page === pageName)),
    refetchOnWindowFocus: false,
  });
};

export const useCreatePageConfig = ({
  onSuccess,
}: QueryCallback<UserPageConfig>) => {
  return useMutation(
    ({ page, agrid_config }: Omit<UserPageConfig, "id">) =>
      createPageConfig({ page, agrid_config }),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useUpdatePageConfig = () => {
  return useMutation(
    ({ id, page, agrid_config }: UserPageConfig) =>
      updatePageConfig({ page, agrid_config, id }),
    {
      mutationKey: "ignoreMutation",
    }
  );
};

export const useListAllChannel = ({ onSuccess }: QueryCallback<Channel[]>) => {
  return useQuery(["list-all-channels"], listAllChannels, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useListAllChannelByIds = (
  ids: number[],
  { onSuccess }: QueryCallback<Channel[]>
) => {
  return useQuery(
    ["list-all-channels", ids],
    () => (ids ? listAllChannels() : undefined),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useCreateCampaign = ({ onSuccess }: QueryCallback<Campaign>) => {
  return useMutation(createCampaign, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useListAllCampaign = (
  enabled: boolean,
  { onSuccess, onError }: QueryCallback<Campaign[]>
) => {
  return useQuery(["list-all-campaigns"], listCampaigns, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError: (data) => {
      onError && onError(data);
    },
    enabled: enabled,
    refetchOnWindowFocus: false,
  });
};

export const useBulkDuplicationCampaign = ({
  onSuccess,
  onError,
}: QueryCallback<Campaign[]>) => {
  return useMutation((ids: any[]) => bulkDuplicationCampaign(ids), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useBulkArchiveCampaign = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((ids: any[]) => bulkArchiveCampaign(ids), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useBulkStopCampaign = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((ids: any[]) => bulkStopCampaign(ids), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useListAllCampaignAnalytics = (
  callback?: QueryCallback<ServerSideResponse<CampaignAnalytics>>
) => {
  const { onSuccess } = callback ?? {};
  return useQuery(
    ["list-all-campaign-analytics"],
    () =>
      fetchCampaignsAnalytics({
        filterModel: {},
        endRow: 1000,
        startRow: 0,
        sortModel: [],
      }),
    {
      onSuccess: (data) => onSuccess && onSuccess(data),
      refetchOnWindowFocus: false,
    }
  );
};

export const useListAllCampaignDashboard = (
  callback?: QueryCallback<ServerSideResponse<Campaign>>
) => {
  const { onSuccess } = callback ?? {};
  return useQuery(
    ["list-all-campaign-dashboard"],
    () =>
      fetchCampaignsDashboard({
        filterModel: {},
        endRow: 1000,
        startRow: 0,
        sortModel: [],
      }),
    {
      onSuccess: (data) => onSuccess && onSuccess(data),
      refetchOnWindowFocus: false,
    }
  );
};

export const useCampaign = (
  id: string,
  { onSuccess }: QueryCallback<Campaign>
) => {
  return useQuery(["get-campaign-id", id], () => getCampaignById(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useUpdateCampaign = (
  id: number,
  callback?: QueryCallback<Campaign>
) => {
  return useMutation((campaign: Campaign) => updateCampaignById(id, campaign), {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
    onError: (error) => callback?.onError && callback.onError(error),
  });
};

export const useListTemplate = (callback?: QueryCallback<Template[]>) => {
  return useQuery(["list-template"], listTemplates, {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
    refetchOnWindowFocus: true,
  });
};

export const useTemplateReview = (
  id?: number,
  callback?: QueryCallback<TemplateExample>
) => {
  return useQuery(
    ["random-template", id],
    () => (id ? randomTemplatReview(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetMessageTemplate = (
  query: MessageQuery,
  callback?: QueryCallback<MessageTemplate[]>
) => {
  return useQuery(
    ["list-message-template", query.campaign_id],
    () => {
      if (query.campaign_id) {
        return listMessageTemplates(query);
      }
      return undefined;
    },
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useListAllMessageTemplate = (
  callback?: QueryCallback<MessageTemplate[]>
) => {
  return useQuery(["list-all-message-template"], () => listMessageTemplates(), {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useTrackingFunctions = (callback?: QueryCallback<any>) => {
  return useQuery(["list-tracking-functions"], getTrackingFunctions, {
    refetchOnWindowFocus: false,
  });
};

export const useUpdateMessageTemplate = (
  callback?: QueryCallback<MessageTemplate>
) => {
  return useMutation(
    (template: MessageTemplate) => updateMessageTemplates(template),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
    }
  );
};

export const useUpdateCampaignFromTable = (
  callback?: QueryCallback<Campaign>
) => {
  return useMutation(
    (campaign: Campaign) => updateCampaignByIdFromTable(campaign),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      onError: (data) => {
        callback?.onError && callback.onError(data);
      },
    }
  );
};

export const useListAllMessage = ({
  onSuccess,
}: QueryCallback<MessageTemplate[]>) => {
  return useQuery(["list-all-message"], listMessage, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useListMessageTemplate = (
  callback?: QueryCallback<PushMessageTemplate[]>
) => {
  return useQuery(["list-message-template"], listPushMessageTemplates, {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useMessagePools = (
  id?: number | string,
  callback?: QueryCallback<PushMessageTemplate>
) => {
  return useQuery(
    ["random-template", id],
    () => (id ? getmessagePoolsById(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useListAllPoolsDashboard = (
  callback?: QueryCallback<ServerSideResponse<PushMessageTemplate>>
) => {
  const { onSuccess } = callback ?? {};
  return useQuery(
    ["list-all-pools-dashboard"],
    () =>
      fetchPoolsDashboard({
        filterModel: {},
        endRow: 1000,
        startRow: 0,
        sortModel: [],
      }),
    {
      onSuccess: (data) => onSuccess && onSuccess(data),
      refetchOnWindowFocus: false,
    }
  );
};

export const useCreatePushMessageTemplate = ({
  onSuccess,
}: QueryCallback<PushMessageTemplate>) => {
  return useMutation(createPushMessageTemplate, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useBulkDuplicationPushMessageTemplate = ({
  onSuccess,
  onError,
}: QueryCallback<PushMessageTemplate[]>) => {
  return useMutation((ids: any[]) => bulkDuplicationPushMessageTemplate(ids), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useBulkArchivePushMessageTemplate = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((ids: any[]) => bulkArchivePushMessageTemplate(ids), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useUpdatePushMessageTemplate = (
  id: number,
  callback?: QueryCallback<PushMessageTemplate>
) => {
  return useMutation(
    (pushMessageTemplate: PushMessageTemplate) =>
      updatePushMessageTemplateById(id, pushMessageTemplate),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
    }
  );
};

export const useListAllImage = (
  image_ids?: Array<number>,
  callback?: QueryCallback<Array<ImageObj>>
) => {
  return useQuery(
    ["random-template", image_ids],
    () =>
      image_ids && image_ids.length > 0 ? listImage(image_ids) : undefined,
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useExportChannelSubscribers = (
  id: string,
  startTime: string | undefined,
  endTime: string | undefined,
  extension: string | undefined,
  { onSuccess, onError }: QueryCallback<any>
) => {
  return useQuery(
    ["export-channel-subscriber", extension],
    () =>
      extension
        ? getExportChannelSubscribers({
            channel: Number(id),
            start: startTime,
            end: endTime,
            extension: extension,
          })
        : null,
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data);
        }
      },
      onError: (error) => onError && onError(error),
      refetchOnWindowFocus: false,
    }
  );
};

export const useIndicatorsAnalyticsData = (
  ids: string[] | undefined,
  startTime: string | undefined,
  endTime: string | undefined,
  { onSuccess }: QueryCallback<any>
) => {
  return useQuery(
    ["indicators-analytics-data", ids, startTime, endTime],
    () =>
      getIndicatorsAnalyticsData({
        channels: ids,
        start: startTime,
        end: endTime,
      }),
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data.data);
        }
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useListAllSubscribersSourcesDashboard = (
  callback?: QueryCallback<ServerSideResponse<SubscribersSource>>
) => {
  const { onSuccess } = callback ?? {};
  return useQuery(
    ["list-all-subscribers-sources-dashboard"],
    () =>
      fetchSubscribersSourcesDashboard({
        filterModel: {},
        endRow: 1000,
        startRow: 0,
        sortModel: [],
      }),
    {
      onSuccess: (data) => onSuccess && onSuccess(data),
      refetchOnWindowFocus: false,
    }
  );
};

export const useListAllWindowsPoolsDashboard = (
  callback?: QueryCallback<ServerSideResponse<WindowsPools>>
) => {
  const { onSuccess } = callback ?? {};
  return useQuery(
    ["list-all-windows-pools-dashboard"],
    () =>
      fetchWindowsPoolsDashboard({
        filterModel: {},
        endRow: 1000,
        startRow: 0,
        sortModel: [],
      }),
    {
      onSuccess: (data) => onSuccess && onSuccess(data),
      refetchOnWindowFocus: false,
    }
  );
};

export const useExportIndicatorsAnalytics = (
  exportAnalyticsQuery: exportAnalyticsQuery,
  extension: string | undefined,
  { onSuccess, onError }: QueryCallback<any>
) => {
  return useQuery(
    ["export-indicators-analytics", extension],
    () => (extension ? getIndicatorsAnalytics(exportAnalyticsQuery) : null),
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data);
        }
      },
      onError,
      refetchOnWindowFocus: false,
    }
  );
};

export const useWindowsPools = (
  id?: number | string,
  callback?: QueryCallback<WindowsPools>
) => {
  return useQuery(
    ["windows-pools", id],
    () => (id ? getWindowsPoolsById(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useListAllAutocomplete = (
  enabled: boolean,
  { onSuccess, onError }: QueryCallback<WindowsPools[]>
) => {
  return useQuery(["list-all-Autocomplete"], getWindowsPoolsAutocomplete, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError: (data) => {
      onError && onError(data);
    },
    enabled: enabled,
    refetchOnWindowFocus: true,
  });
};

export const useCreatewindowsPools = ({
  onSuccess,
  onError,
}: QueryCallback<WindowsPools>) => {
  return useMutation(createwindowsPools, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError: (data) => {
      onError && onError(data);
    },
  });
};

export const useUpdateWindowsPoolsById = (
  id: number,
  callback?: QueryCallback<WindowsPools>
) => {
  return useMutation(
    (windowsPools: WindowsPools) => updateWindowsPoolsById(id, windowsPools),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
    }
  );
};

export const useListSubscribersSourceNames = (
  channel_id: string | number | undefined,
  { onSuccess }: QueryCallback<SubscribersSourceList[]>
) => {
  return useQuery(
    ["list-subscribers-source-names"],
    () =>
      channel_id ? listSubscribersSourceNames(Number(channel_id)) : undefined,
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateWindowsPoolsWinnerById = (
  callback?: QueryCallback<WindowsPools>
) => {
  return useMutation(
    (windowsPoolsWinner: WindowsPoolsWinnerQuery) =>
      updateWindowsPoolsWinnerById(windowsPoolsWinner),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
    }
  );
};

export const useBulkBannedWindowsPools = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation(
    ({ window_pool_id, ids }: { window_pool_id: string; ids: any[] }) =>
      bulkBannedWindowsPools(window_pool_id, ids),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      onError,
    }
  );
};

export const useListAllSubscriptionWindowsDashboard = (
  callback?: QueryCallback<ServerSideResponse<SubscriptionWindow>>
) => {
  const { onSuccess } = callback ?? {};
  return useQuery(
    ["list-all-subscription-windows-dashboard"],
    () =>
      fetchSubscriptionWindowsDashboard({
        filterModel: {},
        endRow: 1000,
        startRow: 0,
        sortModel: [],
      }),
    {
      onSuccess: (data) => onSuccess && onSuccess(data),
      refetchOnWindowFocus: false,
    }
  );
};

export const useCreateSubscriptionWindow = ({
  onSuccess,
}: QueryCallback<SubscriptionWindow>) => {
  return useMutation(createSubscriptionWindow, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useUpdateSubscriptionWindow = (
  id: number,
  callback?: QueryCallback<SubscriptionWindow>
) => {
  return useMutation(
    (subscriptionWindow: SubscriptionWindow) =>
      updateSubscriptionWindowById(id, subscriptionWindow),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
    }
  );
};

export const useSubscriptionWindow = (
  id?: number | string,
  callback?: QueryCallback<SubscriptionWindow>
) => {
  return useQuery(
    ["subscription-window", id],
    () => (id ? getSubscriptionWindowById(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      onError: (error) => callback?.onError && callback.onError(error),
      refetchOnWindowFocus: false,
    }
  );
};

export const useCampaignsAnalyticsData = (
  ids: string | undefined,
  startTime: string | undefined,
  endTime: string | undefined,
  { onSuccess }: QueryCallback<any>
) => {
  return useQuery(
    ["campaign-analytics-data", ids, startTime, endTime],
    () =>
      getCampaignsAnalyticsData({
        campaign: ids,
        start: startTime,
        end: endTime,
      }),
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data.data);
        }
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetUserInfo = ({ onSuccess }: QueryCallback<usersInfo>) => {
  return useQuery(["user-info"], () => getUserInfo(), {
    onSuccess: (data) => {
      if (data) {
        onSuccess && onSuccess(data);
      }
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetUserInfoWithToken = (
  token: string | undefined,
  { onSuccess }: QueryCallback<usersInfo>
) => {
  return useQuery(
    ["user-info-WithToken"],
    () => (token ? getUserInfo() : undefined),
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data);
        }
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetUserQuotas = (
  token: string | undefined,
  { onSuccess }: QueryCallback<userQuotas>
) => {
  return useQuery(
    ["user-quotas"],
    () => (token ? getUserQuotas() : undefined),
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data);
        }
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetUserSubscribersLimitations = (
  token: string | undefined,
  { onSuccess }: QueryCallback<SubscribersLimitations>
) => {
  return useQuery(
    ["user-Subscribers-Limitations"],
    () => (token ? getUserSubscribersLimitations() : undefined),
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data);
        }
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetUserBilling = (
  token: string | undefined,
  { onSuccess }: QueryCallback<usersInfo>
) => {
  return useQuery(
    ["user-billing", token],
    () => (token ? getUserInfo() : undefined),
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data);
        }
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateUsersInfo = (callback?: QueryCallback<usersInfo>) => {
  return useMutation((usersInfo: usersInfo) => updateUserProfile(usersInfo), {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
    onError: (error) => callback?.onError && callback.onError(error),
  });
};

export const useUpdateUserPassword = (
  callback?: QueryCallback<usersPassword>
) => {
  return useMutation(
    (usersPassword: usersPassword) => updateUserPassword(usersPassword),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      onError: (error) => callback?.onError && callback.onError(error),
    }
  );
};

export const useLoginRequest = ({
  onSuccess,
  onError,
}: QueryCallback<AuthResponse>) => {
  return useMutation(loginApi, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useGetUserPasswordVerify = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((password: string) => getUserPasswordVerify(password), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
    mutationKey: "ignoreMutation",
  });
};

export const useNotificationsCount = ({
  onSuccess,
}: QueryCallback<notificationCount>) => {
  return useQuery(["list-notifications-count"], notificationsCount, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus: true,
    refetchInterval: 10000,
  });
};

export const useUpdateNotificatioStatus = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation(
    (queryNotificationStatus: notificationStatusObj) =>
      updateNotificatioStatus(queryNotificationStatus),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      onError,
      mutationKey: "ignoreMutation",
    }
  );
};

export const useUserResetPassword = ({ onSuccess }: QueryCallback<any>) => {
  return useMutation(UserResetPassword, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useUpdateNewPassword = (callback?: QueryCallback<any>) => {
  return useMutation((query: ResetPasswordQuery) => updateNewPassword(query), {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
    onError: (error) => callback?.onError && callback.onError(error),
  });
};

export const useMessagePoolReivew = (
  id?: number,
  callback?: QueryCallback<TemplateExample>
) => {
  return useQuery(
    ["message-examples", id],
    () => (id ? getMessagePoolReivew(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useExportCampaignAnalytics = (
  query: exportCampaignAnalyticsQuery,
  extension: string | undefined,
  { onSuccess, onError }: QueryCallback<any>
) => {
  return useQuery(
    ["export-campaign-analytics", extension],
    () => (extension ? getCampaignAnalytics(query) : null),
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data);
        }
      },
      onError: (error) => onError && onError(error),
      refetchOnWindowFocus: false,
    }
  );
};

export const useExportContentInsights = (
  query: exportContentQuery,
  extension: string | undefined,
  { onSuccess, onError }: QueryCallback<any>
) => {
  return useQuery(
    ["export-content-insights", extension],
    () =>
      extension && query.campaign_id && query.pool_id
        ? getContentInsights(query)
        : null,
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data);
        }
      },
      onError,
      refetchOnWindowFocus: false,
    }
  );
};

export const useListSubscriptionWindowAutocomplete = ({
  onSuccess,
}: QueryCallback<SubscriptionWindow[]>) => {
  return useQuery(
    ["list-Subscription-Window-Autocomplete"],
    getSubscriptionWindowAutocomplete,
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useWindowsPoolsReview = (
  id?: string | number,
  callback?: QueryCallback<WindowsPreview>
) => {
  return useQuery(
    ["windows-pools-preview-by-id", id],
    () => (!!id ? getWindowsPreviewReadById(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useDeleteChannel = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string) => deleteChannel(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useDeleteWindowsPools = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string) => deleteWindowsPoolsById(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useBulkDuplicationWindowsPools = ({
  onSuccess,
  onError,
}: QueryCallback<WindowsPools[]>) => {
  return useMutation((ids: any[]) => bulkDuplicationWindowsPools(ids), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useBulkArchiveWindowsPools = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((ids: any[]) => bulkArchiveWindowsPools(ids), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useListAllSegments = ({
  onSuccess,
}: QueryCallback<Segments[]>) => {
  return useQuery(["list-all-segments"], listAllSegments, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useRandomWindowsPoolsReview = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useQuery(["random-windows-pools"], randomWindowsPoolsReview, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useSearchSegments = ({
  onSuccess,
  ignoreLoading,
}: QueryCallback<Segments[]>) => {
  return useMutation(
    ({ searchString }: SegmentsSearch) =>
      getSearchSegments({
        searchString,
      }),
    {
      mutationKey: ignoreLoading ? "ignoreMutation" : undefined,
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useGetChannelCodeSnippet = (
  token?: string,
  callback?: QueryCallback<ChannelDetail>
) => {
  return useQuery(
    ["get-channel-code-snippet", token],
    () => (!!token ? getChannelCodeSnippet(token) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useListAllSegmentsAutocomplete = ({
  onSuccess,
}: QueryCallback<Segments[]>) => {
  return useQuery(["list-all-Segments-Autocomplete"], getSegmentsAutocomplete, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useExportABtestReport = (
  query: exportABtestReportQuery,
  extension: string | undefined,
  { onSuccess, onError }: QueryCallback<any>
) => {
  return useQuery(
    ["export-indicators-analytics", extension],
    () => (extension ? getABTestReport(query) : null),
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data);
        }
      },
      onError,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSegment = (
  id?: string | number,
  callback?: QueryCallback<Segments>
) => {
  return useQuery(
    ["get-segment-id", id],
    () => (!!id ? getSegmentById(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateSegment = (callback?: QueryCallback<Segments>) => {
  return useMutation((segment: Segments) => updateSegment(segment), {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
  });
};

export const useCreateSegment = ({ onSuccess }: QueryCallback<Segments>) => {
  return useMutation(createSegment, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useDeleteSegment = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string) => deleteSegment(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useUpdateSubscribersParamsById = (
  callback?: QueryCallback<string[]>
) => {
  return useMutation(
    ({
      id,
      subscribersParams,
    }: {
      id: string | number;
      subscribersParams: string[];
      callback?: () => void;
    }) => updateSubscribersParamsById(id, subscribersParams),
    {
      onSuccess: (data, variables) => {
        variables.callback && variables.callback();
        callback?.onSuccess && callback.onSuccess(data);
      },
      mutationKey: callback?.ignoreLoading ? "ignoreMutation" : undefined,
    }
  );
};

export const useGetsourceLinkParamsById = (
  id?: string,
  callback?: QueryCallback<string[]>
) => {
  return useQuery(
    ["get-source-link-params", id],
    () => (!!id ? getSubscribersParamsById(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useCheckoutPayment = ({
  onSuccess,
}: QueryCallback<{ redirect_url: string }>) => {
  return useMutation((price_lookup?: string) => CheckoutPayment(price_lookup), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useUpdateCheckoutPayment = ({
  onSuccess,
}: QueryCallback<{ redirect_url: string }>) => {
  return useMutation(UpdateCheckoutPayment, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useGetCheckoutPaymentValidate = (
  session_id?: string | number,
  callback?: QueryCallback<UserPayment[]>
) => {
  return useQuery(
    ["get-source-link-params", session_id],
    () => (!!session_id ? getCheckoutPaymentValidate(session_id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      onError: (error: { message: string; code: string }) =>
        callback?.onError && callback.onError(error.message, error.code),
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetAvailablePricesList = (
  callback?: QueryCallback<Prices[]>
) => {
  return useQuery(["get-available-prices-list"], getAvailablePricesList, {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
    onError: (error: { message: string; code: string }) =>
      callback?.onError && callback.onError(error.message, error.code),
    refetchOnWindowFocus: false,
  });
};

export const useGetSubscriptionDetails = (
  token: string | undefined,
  callback?: QueryCallback<UserSubscription>
) => {
  return useQuery(
    ["get-user-subscription-details", token],
    () => (!!token ? getUserSubscriptionDetails() : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      onError: (error: { message: string; code: string }) =>
        callback?.onError && callback.onError(error.message, error.code),
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateUsersSubscriptionPlan = ({
  onSuccess,
}: QueryCallback<any>) => {
  return useMutation(
    (price_lookup: string) => UpdateUsersSubscriptionPlan(price_lookup),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useCancelUsersSubscriptionPlan = ({
  onSuccess,
}: QueryCallback<any>) => {
  return useMutation(CancelUsersSubscriptionPlan, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useGetUserInvoicesList = (
  callback?: QueryCallback<UserInvoices[]>
) => {
  return useQuery(["get-user-invoices-list"], getUserInvoices, {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
    onError: (error: { message: string; code: string }) =>
      callback?.onError && callback.onError(error.message, error.code),
    refetchOnWindowFocus: false,
  });
};

export const useDownloadWebSdk = ({ onSuccess }: QueryCallback<any>) => {
  return useMutation(() => getDownloadWebSdk(), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useGetSubscribersTotal = (callback?: QueryCallback<any>) => {
  return useQuery(["get-subscribers-total"], getSubscribersTotal, {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
    onError: (error: { message: string; code: string }) =>
      callback?.onError && callback.onError(error.message, error.code),
    refetchOnWindowFocus: false,
  });
};

export const useGetChannelPWA = ({ onSuccess }: QueryCallback<any>) => {
  return useMutation((channel: Channel) => getChannelPWA(channel), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useGetUserFirebaseCredentialsList = (
  callback?: QueryCallback<FirebaseAccounts[]>
) => {
  return useQuery(
    ["user-firebase-credentials"],
    getUserFirebaseCredentialsList,
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      onError: (error: { message: string; code: string }) =>
        callback?.onError && callback.onError(error.message, error.code),
      refetchOnWindowFocus: false,
    }
  );
};

export const useCreateFirebaseAccounts = ({
  onSuccess,
}: QueryCallback<FirebaseAccounts>) => {
  return useMutation(createFirebaseAccounts, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useUpdateFirebaseAccounts = ({
  onSuccess,
}: QueryCallback<any>) => {
  return useMutation(
    (firebaseAccounts: FirebaseAccounts) =>
      UpdateFirebaseAccounts(firebaseAccounts),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useDeleteFirebaseAccounts = ({
  onSuccess,
}: QueryCallback<any>) => {
  return useMutation((id: number) => DeleteFirebaseAccounts(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useGetFirebaseAccountsById = (
  id?: number,
  callback?: QueryCallback<FirebaseAccounts>
) => {
  return useQuery(
    ["get-firebase-accounts-by-id", id],
    () => (!!id ? getFirebaseAccountsById(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useArchiveChannel = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string | number) => archiveChannel(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useUnArchiveChannel = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string | number) => unArchiveChannel(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useArchiveCampaigns = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string | number) => archiveCampaign(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useUnArchiveCampaigns = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string | number) => unArchiveCampaign(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useArchiveSegment = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string | number) => archiveSegment(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useUnArchiveSegment = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string | number) => unArchiveSegment(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useArchivePushNotification = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string | number) => archivePushNotification(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useUnArchivePushNotification = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string | number) => unArchivePushNotification(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useArchiveWindowsPools = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string | number) => archiveWindowsPools(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useUnArchiveWindowsPools = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string | number) => unArchiveWindowsPools(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useDeleteCampaign = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string) => deleteCampaign(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useDeletePushMessageTemplate = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((id: string) => deletePushMessageTemplateById(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useListAllSegmentsAutocompleteByChannelId = (
  { onSuccess }: QueryCallback<Segments[]>,
  query?: SegmentsSearch
) => {
  return useQuery(
    [
      "list-all-Segments-Autocomplete-By-Channel-Id",
      query?.channel_id,
      query?.searchString,
    ],
    () => listSegments(query),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      refetchOnWindowFocus: true,
    }
  );
};

// export const useListAllSegmentsAutocompleteByChannelId = ({
//   onSuccess,
//   ignoreLoading,
// }: QueryCallback<Segments[]>) => {
//   return useMutation(
//     ({ searchString }: SegmentsSearch) =>
//       listSegments({
//         searchString,
//       }),
//     {
//       mutationKey: ignoreLoading ? "ignoreMutation" : undefined,
//       onSuccess: (data) => {
//         onSuccess && onSuccess(data);
//       },
//     }
//   );
// };

export const useNewRefreshTokenApi = (
  { onSuccess }: QueryCallback<AuthResponse>,
  userToken?: string
) => {
  return useQuery(
    ["useNewRefreshTokenApi", userToken],
    () => (!!userToken ? refreshNewTokenApi(userToken) : undefined),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      refetchOnWindowFocus: true,
    }
  );
};

export const usePoolOptimisation = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation(
    ({
      id,
      payload,
    }: {
      id: string | number;
      payload: PoolOptimisationConfig;
    }) => poolOptimisation(id, payload),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      onError,
    }
  );
};

export const usePoolOptimisationById = (
  id?: string | number,
  callback?: QueryCallback<ContentInsightsFavroites>
) => {
  return useQuery(
    ["get-Pool-Optimisation-by-id", id],
    () => (!!id ? getPoolOptimisationById(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useEvent = (
  id?: string,
  callback?: QueryCallback<EventObject>
) => {
  return useQuery(
    ["get-events-id", id],
    () => (!!id ? getEventsById(id) : undefined),
    {
      onSuccess: (eventObject) => {
        callback?.onSuccess && callback.onSuccess(eventObject);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateEvent = (callback?: QueryCallback<EventObject>) => {
  return useMutation((eventObject: EventObject) => updateEvents(eventObject), {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
  });
};

export const useCreateEvent = ({ onSuccess }: QueryCallback<EventObject>) => {
  return useMutation(createEvents, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useListAllEvents = ({
  onSuccess,
}: QueryCallback<EventObject[]>) => {
  return useQuery(["list-all-events"], listAllEvents, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus: true,
  });
};

export const useEventsAnalyticsData = (
  event_id: number | undefined,
  startTime: string | undefined,
  endTime: string | undefined,
  { onSuccess }: QueryCallback<any>
) => {
  return useQuery(
    ["Events-analytics-data", event_id, startTime, endTime],
    () =>
      getEventsAnalyticsData({
        event_id: event_id,
        start: startTime,
        end: endTime,
      }),
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data.data);
        }
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useExportEventsAnalytics = (
  query: exportEventsAnalyticsQuery,
  extension: string | undefined,
  { onSuccess, onError }: QueryCallback<any>
) => {
  return useQuery(
    ["export-Events-analytics", extension],
    () => (extension ? getEventsAnalytics(query) : null),
    {
      onSuccess: (data) => {
        if (data) {
          onSuccess && onSuccess(data);
        }
      },
      onError: (error) => onError && onError(error),
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateJourneyName = (callback?: QueryCallback<Journey>) => {
  return useMutation((journey: Journey) => updateJourneyName(journey), {
    onSuccess: (data) => {
      callback?.onSuccess && callback.onSuccess(data);
    },
    onError: (error) => callback?.onError && callback.onError(error),
  });
};

export const useListAllJourneyAutocomplete = ({
  onSuccess,
}: QueryCallback<Journey[]>) => {
  return useQuery(["list-all-Journey-Autocomplete"], getJourneyAutocomplete, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useCreateJourney = ({ onSuccess }: QueryCallback<Journey>) => {
  return useMutation(createJourney, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export const useJourney = (id?: string, callback?: QueryCallback<Journey>) => {
  return useQuery(
    ["get-journey-id", id],
    () => (!!id ? getJourneyById(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useJourneyElementsStructure = (
  id?: string | number,
  callback?: QueryCallback<JourneyElements[]>
) => {
  return useQuery(
    ["get-journey-Elements-Structure-id", id],
    () => (!!id ? getJourneyElementsStructure(id) : undefined),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback.onSuccess(data);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateJourney = ({
  onSuccess,
  onError,
}: QueryCallback<Journey>) => {
  return useMutation((payload: Journey) => updateJourney(payload), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useDeleteJourney = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation((payload: Journey) => deleteJourney(payload), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useArchiveJourney = ({
  onSuccess,
  onError,
}: QueryCallback<Journey>) => {
  return useMutation((id: string) => archiveJourney(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useDuplicationJourney = ({
  onSuccess,
  onError,
}: QueryCallback<Journey>) => {
  return useMutation((id: string) => duplicationJourney(id), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useUpdateJourneyElements = ({
  onSuccess,
  onError,
}: QueryCallback<JourneyElements>) => {
  return useMutation(
    (payload: JourneyElements) => updateJourneyElementsById(payload),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      onError,
    }
  );
};

export const useCreateJourneyElements = ({
  onSuccess,
  onError,
}: QueryCallback<JourneyElements>) => {
  return useMutation(
    (payload: JourneyElements) => createJourneyElementsById(payload),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      onError,
    }
  );
};

export const useDeleteJourneyElements = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation(
    (payload: JourneyElements) => deleteJourneyElementsById(payload),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      onError,
    }
  );
};

export const useResendVerificationEmailApi = ({
  onSuccess,
  onError,
}: QueryCallback<any>) => {
  return useMutation(() => resendVerificationEmailApi(), {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export const useChannelPostBackEcho = (
  channelToken?: string,
  callback?: QueryCallback<PostbackEcho[]>
) => {
  return useQuery(
    ["postbacks", channelToken],
    async () => {
      if (channelToken) {
        return await getPostbackForChannel(channelToken);
      }
    },
    { onSuccess: callback?.onSuccess, refetchOnWindowFocus: false }
  );
};

export const useCreatePostback = (callback?: QueryCallback<PostbackEcho>) => {
  return useMutation(
    ({
      channelToken,
      postback,
    }: {
      channelToken: string;
      postback: PostbackEcho;
      callback?: () => void;
    }) => createPostbackEcho(channelToken, postback),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback?.onSuccess(data);
      },
      onError: (error: { message: string; code: string }) =>
        callback?.onError && callback?.onError(error.message, error.code),
    }
  );
};

export const useUpdatePostback = (callback?: QueryCallback<PostbackEcho>) => {
  return useMutation(
    ({
      channelToken,
      postback,
    }: {
      channelToken: string;
      postback: PostbackEcho;
      callback?: () => void;
    }) => updatePostbackEcho(channelToken, postback),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback?.onSuccess(data);
      },
      onError: (error: { message: string; code: string }) =>
        callback?.onError && callback?.onError(error.message, error.code),
    }
  );
};

export const useDeletePostback = (callback?: QueryCallback<PostbackEcho>) => {
  return useMutation(
    ({
      channelToken,
      id,
    }: {
      channelToken: string;
      id: number;
      callback?: () => void;
    }) => deletePostbackEcho(channelToken, id),
    {
      onSuccess: (data) => {
        callback?.onSuccess && callback?.onSuccess(data);
      },
      onError: (error: { message: string; code: string }) =>
        callback?.onError && callback?.onError(error.message, error.code),
    }
  );
};
